import React from 'react';
import ReactDOM from 'react-dom';
import Navigation from './components/overlays/NavigationComponent'; // Import your navigation file
import './index.css'; // Your global styles

import { Auth0Provider } from '@auth0/auth0-react';
import ApolloTracker from './components/overlays/ApolloTracker';

const domain = "raah-website-revamp.us.auth0.com";
const clientId = "e4IqGAtoY0qgKrz1BHFl3zaGwqmsNWs4";
const audience = "https://protected.raahtech.com/secure-pdf";


ReactDOM.render(
  <React.StrictMode>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          redirect_uri: window.location.origin+"/whitepaper/nextgeniam2025",
          audience: audience,
          grant_type: 'authorization_code'
        }}
      >
        <ApolloTracker />
        <Navigation />
      </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
