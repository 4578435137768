import React, { useEffect } from "react";

const ApolloTracker = () => {
  useEffect(() => {
    // Function to initialize Apollo script
    const initApollo = () => {
      const uniqueId = Math.random().toString(36).substring(7);
      const script = document.createElement("script");

      script.src = `https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=${uniqueId}`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        if (window.trackingFunctions && window.trackingFunctions.onLoad) {
          window.trackingFunctions.onLoad({
            appId: "66d9baa783833801b2b9d9e5",
          });
        }
      };

      document.head.appendChild(script);
    };

    // Call the initApollo function
    initApollo();

    // Optional cleanup (if necessary)
    return () => {
      // Remove the script if needed during component unmount
      const script = document.querySelector(
        'script[src^="https://assets.apollo.io/micro/website-tracker/tracker.iife.js"]'
      );
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, []); // Empty dependency array ensures this runs only once on mount

  return null; // No UI is needed for this tracker
};

export default ApolloTracker;