import React, { useState, useEffect } from 'react';
import './SolutionsComponent1.css';

// Import solution icons (SVGs and GIFs will share similar names)
import Industry1 from '../icons/ByIndustry.svg';
import Platform1 from '../icons/ByTechnology.svg';
import UseCase1 from '../icons/ByUseCase.svg';

import IndustryGif from '../icons/ByIndustry.gif';
import PlatformGif from '../icons/ByTechnology.gif';
import UseCaseGif from '../icons/ByUseCase.gif';

function SolutionsComponent1() {
  const [showInitialTitle, setShowInitialTitle] = useState(false);
  const [isTitleShrunk, setIsTitleShrunk] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    // Observer to handle scroll into view behavior for title, paragraph, and grid
    const componentObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowInitialTitle(true);
            setTimeout(() => {
              setIsTitleShrunk(true);
              setTimeout(() => {
                setShowContent(true);
              }, 150);
            }, 500);
            componentObserver.unobserve(entry.target); // Run the animation only once
          }
        });
      },
      { threshold: 0.5 }
    );

    const componentElement = document.querySelector('.sc1_container');
    if (componentElement) {
      componentObserver.observe(componentElement);
    }

    return () => {
      if (componentElement) {
        componentObserver.unobserve(componentElement);
      }
    };
  }, []);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const scrollToSection = (sectionId) => {
    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  // Card data including the paths to SVGs and GIFs
  const cardData = [
    {
      image: Industry1,
      gif: IndustryGif,
      title: 'Industries',
      description: 'Identity solutions for a plethora of Business Verticals',
      sectionId: 'solutions-component-2',
    },
    {
      image: Platform1,
      gif: PlatformGif,
      title: 'Platforms',
      description:
        'Solutions built with Okta, Ping Identity, Saviynt, and more.',
      sectionId: 'solutions-component-3',
    },
    {
      image: UseCase1,
      gif: UseCaseGif,
      title: 'Use Cases',
      description: 'Expertise in CIAM, Workforce, and PAM identity challenges.',
      sectionId: 'solutions-component-4',
    },
  ];

  return (
    <div className="sc1_container">
      <div
        className={`sc1_title ${showInitialTitle ? 'show' : ''} ${
          isTitleShrunk ? 'shrink' : ''
        }`}
      >
        <h2>
          Our <span className="sc1_highlight">Solutions</span>
        </h2>
      </div>

      <div className={`sc1_paragraph ${showContent ? 'show' : ''}`}>
        <p>
          <span className="sc1_highlight">RAAH</span> delivers comprehensive
          identity solutions across diverse industries, leveraging a wide range
          of platforms to tackle complex use cases.
          <br />
          We bring clarity to{' '}
          <span className="sc1_highlight">every perspective</span> of identity.
        </p>
      </div>

      {/* Solution Grid Section */}
      <div className={`sc1_grid ${showContent ? 'show' : ''}`}>
        {cardData.map((item, index) => (
          <div
            key={index}
            className={`sc1_item ${hoveredIndex === index ? 'hovered' : ''}`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={hoveredIndex === index ? item.gif : item.image}
              alt={item.title}
              className="sc1_item-img"
            />
            <p className="sc1_item-text">{item.title}</p>
            <p className="sc1_item-description">{item.description}</p>
            <button
              className="sc1_learnMoreButton"
              onClick={() => scrollToSection(item.sectionId)}
            >
              Learn More
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SolutionsComponent1;
