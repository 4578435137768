import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Ensure react-router-dom is installed
// import Industry from './icons/IndustryIcons.svg';
// import Technology from './icons/TechnologyIcons.svg';
// import UseCase from './icons/UseCaseIcons.svg';
import Industry from './icons/IndustryColor.gif';
import Technology from './icons/PlatformColor.gif';
import UseCase from './icons/UseCaseColor.gif';
import './IdentityComponent.css';

const IdentityComponent = ({ scrollPercentage }) => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  // Detect if the screen is mobile based on window width
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to determine if the shape should be shown based on the scroll percentage (for mobile)
  const shouldShowShape = (shapeType) => {
    switch (shapeType) {
      case 'first':
        return scrollPercentage >= 0 && scrollPercentage < 70 / 3;
      case 'second':
        return scrollPercentage >= 70 / 3 && scrollPercentage < 140 / 3;
      case 'third':
        return scrollPercentage >= 140 / 3 && scrollPercentage < 100;
      default:
        return false;
    }
  };

  const getShapeStyle = (shapeType) => {
    const baseStyle = {
      ...styles.shape,
      ...styles.transition,
    };

    const mobileSquareStyle = {
      width: '330px',
      height: '175px',
    };

    switch (shapeType) {
      case 'first':
        if (scrollPercentage >= 0 && scrollPercentage < 70 / 3) {
          return isMobile
            ? { ...baseStyle, ...mobileSquareStyle }
            : { ...baseStyle, ...styles.square };
        } else {
          return isMobile ? {} : { ...baseStyle, ...styles.rectangle };
        }
      case 'second':
        if (scrollPercentage >= 70 / 3 && scrollPercentage < 140 / 3) {
          return isMobile
            ? { ...baseStyle, ...mobileSquareStyle }
            : { ...baseStyle, ...styles.square };
        } else {
          return isMobile ? {} : { ...baseStyle, ...styles.rectangle };
        }
      case 'third':
        if (scrollPercentage >= 140 / 3 && scrollPercentage < 100) {
          return isMobile
            ? { ...baseStyle, ...mobileSquareStyle }
            : { ...baseStyle, ...styles.square };
        } else {
          return isMobile ? {} : { ...baseStyle, ...styles.rectangle };
        }
      default:
        return baseStyle;
    }
  };

  const getTitleStyle = (shapeType) => {
    switch (shapeType) {
      case 'first':
        return scrollPercentage >= 0 && scrollPercentage < 70 / 3
          ? styles.squareTitle
          : styles.rectangleTitle;
      case 'second':
        return scrollPercentage >= 70 / 3 && scrollPercentage < 140 / 3
          ? styles.squareTitle
          : styles.rectangleTitle;
      case 'third':
        return scrollPercentage >= 140 / 3 && scrollPercentage < 100
          ? styles.squareTitle
          : styles.rectangleTitle;
      default:
        return styles.rectangleTitle;
    }
  };

  const getHeaderStyle = (shapeType) => {
    // console.log(scrollPercentage);
    if (
      (shapeType === 'first' &&
        scrollPercentage >= 0 &&
        scrollPercentage < 70 / 3) ||
      (shapeType === 'second' &&
        scrollPercentage >= 70 / 3 &&
        scrollPercentage < 140 / 3) ||
      (shapeType === 'third' &&
        scrollPercentage >= 140 / 3 &&
        scrollPercentage < 100)
    ) {
      return { ...styles.header, ...styles.visible };
    } else {
      return { ...styles.header, ...styles.hidden };
    }
  };

  const getIconStyle = (shapeType) => {
    switch (shapeType) {
      case 'first':
        return scrollPercentage >= 0 && scrollPercentage < 70 / 3
          ? styles.squareIcon
          : styles.rectangleIcon;
      case 'second':
        return scrollPercentage >= 70 / 3 && scrollPercentage < 140 / 3
          ? styles.squareIcon
          : styles.rectangleIcon;
      case 'third':
        return scrollPercentage >= 140 / 3 && scrollPercentage < 100
          ? styles.squareIcon
          : styles.rectangleIcon;
      default:
        return styles.rectangleIcon;
    }
  };

  const getTextContent = () => {
    if (scrollPercentage >= 0 && scrollPercentage < 70 / 3) {
      return (
        <>
          We provide tailored identity services for a{' '}
          <span style={styles.highlight}>plethora</span> of industries ensuring
          compliance, security, and seamless access. Our adaptable solutions
          address business vertical-specific needs while also enhancing{' '}
          <span style={styles.highlight}>Security</span> and{' '}
          <span style={styles.highlight}>UX</span>.
        </>
      );
    } else if (scrollPercentage >= 70 / 3 && scrollPercentage < 140 / 3) {
      return (
        <>
          We partner with leading identity technologies like{' '}
          <span style={styles.highlight}>Okta, Saviynt, CyberArk</span>, and
          more, to deliver integrations using out-of-the-box (OOB) and custom
          functionality. With platforms tailored to different complexities and
          scales, RAAH ensures solutions that meet client needs and drive
          business enablement.
        </>
      );
    } else if (scrollPercentage >= 140 / 3 && scrollPercentage < 100) {
      return (
        <>
          RAAH Technologies delivers{' '}
          <span style={styles.highlight}>
            Identity Solutions across all channels
          </span>
          —Workforce (B2E) for secure employee access, Consumer (B2C, B2B/B2B2C)
          for seamless interactions, and PAM for critical asset protection. We
          ensure security, scalability, and compliance tailored to your business
          needs.
        </>
      );
    } else {
      return 'Scroll to see changes in the left section and the corresponding text updates.';
    }
  };

  return (
    <div className='identity-content-container'
      style={{
        ...styles.container,
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: isMobile ? 'center' : '',
        alignItems: isMobile ? 'center' : '',
      }}
    >
      <div className='identity-content-col1'
        style={{
          ...styles.leftSection,
          alignItems: isMobile ? 'center' : 'flex-end',
          paddingRight: isMobile ? 0 : '5%',
        }}
      >
        {isMobile ? (
          <>
            {shouldShowShape('first') && (
              <div className="identity-content-header" style={getShapeStyle('first')}>
                <div className="identity-content-title" style={getHeaderStyle('first')}>
                  Identity in Every Vertical
                </div>
                <div className="identity-content-image" style={getIconStyle('first')}>
                  <img src={Industry} alt="By Industry Image" width={25} height={25} />
                </div>
                <div className="identity-content-subtitle" style={{ ...styles.title, ...getTitleStyle('first') }}>
                  By Industry
                </div>
              </div>
            )}
            {shouldShowShape('second') && (
              <div className="identity-content-header" style={getShapeStyle('second')}>
                <div className="identity-content-title" style={getHeaderStyle('second')}>
                  Identity in Every Stack
                </div>
                <div className="identity-content-image" style={getIconStyle('second')}>
                  <img src={Technology} alt="By Technology Image" width={25} height={25} />
                </div>
                <div className="identity-content-subtitle" style={{ ...styles.title, ...getTitleStyle('second') }}>
                  By Platform
                </div>
              </div>
            )}
            {shouldShowShape('third') && (
              <div className="identity-content-header" style={getShapeStyle('third')}>
                <div className="identity-content-title" style={getHeaderStyle('third')}>
                  Identity in Everything
                </div>
                <div className="identity-content-image" style={getIconStyle('third')}>
                  <img src={UseCase} alt="By Use Case Image" width={25} height={25} />
                </div>
                <div className="identity-content-subtitle" style={{ ...styles.title, ...getTitleStyle('third') }}>
                  By Use Case
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="identity-content-header" style={getShapeStyle('first')}>
              <div className="identity-content-title" style={getHeaderStyle('first')}>
                Identity in Every Vertical
              </div>
              <div className="identity-content-image" style={getIconStyle('first')}>
                <img src={Industry} alt="By Industry Image" width={40} height={40} />
              </div>
              <div className="identity-content-subtitle" style={{ ...styles.title, ...getTitleStyle('first') }}>
                By Industry
              </div>
            </div>
            {scrollPercentage >= 70 / 3 && (
              <div className="identity-content-header" style={getShapeStyle('second')}>
                <div className="identity-content-title" style={getHeaderStyle('second')}>
                  Identity in Every Stack
                </div>
                <div className="identity-content-image" style={getIconStyle('second')}>
                  <img src={Technology} alt="By Technology Image" width={40} height={40} />
                </div>
                <div className="identity-content-subtitle" style={{ ...styles.title, ...getTitleStyle('second') }}>
                  By Platform
                </div>
              </div>
            )}
            {scrollPercentage >= 140 / 3 && (
              <div className="identity-content-header" style={getShapeStyle('third')}>
                <div className="identity-content-title" style={getHeaderStyle('third')}>
                  Identity in Everything
                </div>
                <div className="identity-content-image" style={getIconStyle('third')}>
                  <img src={UseCase} alt="By Use Case Image" width={40} height={40} />
                </div>
                <div className="identity-content-subtitle" style={{ ...styles.title, ...getTitleStyle('third') }}>
                  By Use Case
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="identity-content-col2 identity-content-text" style={styles.rightSection}>
        <div style={styles.textContainer}>
          <p style={styles.text}>{getTextContent()}</p>
          {/* Conditionally render the Learn More button */}
          {scrollPercentage >= 140 / 3 && scrollPercentage < 100 && (
            <button
              className="learnButton"
              onClick={() => navigate('/solutions')}
            >
              Learn More
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    width: '100%',
    height: '100vh',
    backgroundColor: '#00000000',
  },
  leftSection: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingRight: '5%',
    boxSizing: 'border-box',
  },
  rightSection: {
    width: '50%',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    paddingLeft: '3%',
    boxSizing: 'border-box',
  },
  shape: {
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    margin: '20px 0',
    position: 'relative',
  },
  square: {
    width: '380px',
    height: '200px',
  },
  rectangle: {
    width: '380px',
    height: '100px',
  },
  transition: {
    transition: 'all 0.5s ease-in-out',
  },
  textContainer: {
    maxWidth: '500px',
  },
  text: {
    color: '#ffffff',
    fontSize: '1rem',
    lineHeight: '1.5',
    fontWeight: '300',
    transition: 'opacity 0.5s ease-in-out',
  },
  highlight: {
    color: '#fd5f0d',
    fontWeight: 'bold',
  },
  title: {
    position: 'absolute',
    fontSize: '1rem',
    color: '#282c34',
    fontWeight: '500',
    backgroundColor: '#e4e4e4',
    borderRadius: '10px',
    padding: '2px 4px',
    transition: 'all 0.5s ease-in-out',
  },
  squareTitle: {
    bottom: '20px',
    left: '20px',
  },
  rectangleTitle: {
    left: '20px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  header: {
    position: 'absolute',
    top: '1.2rem',
    left: '20px',
    fontSize: '30px',
    width: '300px',
    color: '#0d0042',
    fontWeight: '500',
    transition: 'opacity 0.5s ease-in-out',
  },
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
  squareIcon: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: '25px',
    height: '25px',
    transition: 'all 0.5s ease-in-out',
  },
  rectangleIcon: {
    position: 'absolute',
    right: '20px',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '25px',
    height: '25px',
    transition: 'all 0.5s ease-in-out',
  },
};

export default IdentityComponent;
